import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import headerAnimation from "../../../../assets/img/animation/1- Header/1- Header/2/2.gif";
import headerAnimation2 from "../../../../assets/img/animation/1- Header/explore.gif";
import headerAnimation3 from "../../../../assets/img/animation/1- Header/1- Header/3/3.gif";

import { useGetVideo } from "../../Queries/event";
import { Image } from 'react-bootstrap';
import image1 from "../../../../assets/img/hero/Layer_1.png"
import image2 from "../../../../assets/img/hero/Layer_2.png"
import image3 from "../../../../assets/img/hero/Layer_3.png"
import pdf from "../../../../assets/img/hero/pdf_file.pdf"

function HeroSection() {
  const { t, i18n } = useTranslation("home");
  const [activeAnimation, setActiveAnimation] = useState(0);
  const animations = [headerAnimation, headerAnimation2, headerAnimation3];
  const { data: getVideo } = useGetVideo();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveAnimation((prev) => (prev + 1) % animations.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [animations.length]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };


  useEffect(() => {
    handleResize();
  }, [location]);

  return (
    <div className="flex flex-col items-center justify-center video-wrapper relative w-full "
      data-aos="fade-down"
    >
      <div className="w-full bg-[#EAE1CF] h-[80vh] hero_dev items-start ">
        <div className="xs:w-[100%]  sm:w-[50%] bottom-0 absolute">
          <Image src={image2} />
        </div>

        <a href={pdf} target="_blank" rel="noopener noreferrer">
          <Image src={image1} className="w-[40%] sm:w-[15%] bottom-[45%] sm:bottom-[50%] left-[32%] sm:left-[42%] absolute hover:scale-105 duration-300" />
        </a>

        <div className={`xs:w-[100%] sm:w-[40%] xs:bottom-[70%] sm:bottom-[30%] ${i18n.language === "en" ? "right-[0%]" : "left-[0%]"}  absolute p-[15px] `} >
          <Image src={image3} className="w-full" />
          <div className=" text-[#6C4F47] text-center text-md lg:text-2xl mt-[10px]">
            {t("headers.des")}
          </div>
        </div>
      </div>

      {/* headers_container */}
      <div
        className="z-[999] w-[88%] lg:w-[80%] xl:w-[60%] rounded-[31.64px] text-center flex flex-col justify-center items-center  my-[5vh]  "
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div
          style={{ background: "#0000004D", border: "1px solid #6E6E6E" }}
          className="w-[100%] p-[20px] rounded-[31.64px]"
        >
          <div className="mb-3">
            <span className="w-[90%] font-[800] text-[130%] lg:text-[280%] md:text-[200%] text-center">
              {t("headers.title")}
            </span>
            <span className="font-[800] text-[130%] lg:text-[280%] md:text-[200%] text-yellow">
              {t("headers.ksa")}
            </span>
          </div>
          <p className="font-light lg:text-[22px] md:text-[14px] mb-10 w-[90%] lg:w-[60%] mx-auto">
            LuxuryKSA {t("headers.description")}
          </p>
          <Link to="/about-us">
            <button className="about-us-btn butt lg:text-[22px] rounded-full font-semibold border-2 p-3">
              {t("headers.about_luxury")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
