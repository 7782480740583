import React from "react";
import Layout from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import { useGetEventList } from "../Queries/event";
import { useClientGlobalContext } from "../Contexts/ClientGlobalContext";
import { EventCard } from "../Landing/Component/FeaturedEvents";
import { useTitle } from "../../../config/global";

export default function BoxOffices () {
  useTitle('Box-Offices');

  const { t, i18n } = useTranslation("box_offices");
  const { data: eventList } = useGetEventList();

  return (
    <Layout>
      <div className="min-h-[100vh] px-md-4 mx-3 mx-lg-5 pb-5 mb-5">
        <div className=""  >
          <h1 className="text-start mt-[120px] text-[250%]  mb-5">
            { t("first-page.title") }
            <span className="text-yellow ">{ t("first-page.title-event") }</span>
          </h1>
        </div>

        <div className="row d-flex flex-wrap ">
          {
             eventList?.map((item) => (
              <EventCard item={ item } widthClass="col-md-3 col-sm-6" />
            )) }
        </div>
      </div>
    </Layout>
  );
}
